<template>
  <div class="inner-pages homepage-4 agents list hp-6 full hd-white">
    <div id="wrapper">
      <section class="properties-list full featured portfolio blog">
        <div class="container">
          <b-card
            style="padding-top: 20px; padding-right: 20px; padding-bottom: 0px"
            class="d-flex justify-content-center res-height-446 res-none"
            no-body
          >
            <div class="col-12 px-0 parallax-searchs">
              <div class="banner-search-wrap">
                <div class="tab-content">
                  <div class="tab-pane fade show active" id="tabs_1">
                    <div>
                      <div
                        class="rld-main-searches"
                        :style="`
                            ${
                              searchOpen
                                ? `border-bottom-left-radius: 0px;
                              border-bottom-right-radius: 0px;`
                                : ''
                            }
                              `"
                      >
                        <div class="row">
                          <div
                            style="padding-left: 1px"
                            class="rld-single-input w-124 res-mpw mrr-10"
                          >
                            <input
                              style="width: 210px"
                              class="ml-2 res_remove_ml res-search-1"
                              type="text"
                              placeholder="Postcode/Town"
                              v-model="keyword"
                              @change="
                                addToQuery('postCode', $event.target.value)
                              "
                            />
                          </div>
                          <div class="rld-single-select mrr-10 w-125">
                            <multiselect
                              v-if="findIfActive('location') == 1"
                              @input="
                                addToQuery('radius', _.get($event, 'value', ''))
                              "
                              :searchable="false"
                              :placeholder="'Distance'"
                              v-model="radiusOptions"
                              select-label=""
                              deselectLabel=""
                              selectedLabel=""
                              :options="[
                                { label: 'Within 1/4 mile', value: '1/4' },
                                { label: ' Within 1/2 mile', value: '1/2' },
                                { label: 'Within 3 miles', value: '3' },
                                {
                                  label: 'Within 5 miles',
                                  value: '5',
                                },
                                { label: 'Within 10 miles', value: '10' },
                                { label: 'Within 15 miles', value: '15' },
                                { label: 'Within 20 miles', value: '20' },
                                { label: 'Within 30 miles', value: '30' },
                                { label: 'Within 40 miles', value: '40' },
                              ]"
                              :clear-on-select="false"
                              :preserve-search="true"
                              label="label"
                              track-by="value"
                            >
                              <template
                                slot="selection"
                                slot-scope="{ values, isOpen }"
                                ><span
                                  class="multiselect__single"
                                  v-if="values.length &amp;&amp; !isOpen"
                                  >{{ values.length }} option{{
                                    values.length > 1 ? "s" : ""
                                  }}
                                  selected</span
                                ></template
                              >
                              <template #tag><p class="d-none"></p> </template>
                            </multiselect>
                          </div>
                          <div class="rld-single-select mrr-10 w-125">
                            <multiselect
                              :searchable="false"
                              ref="multiselect"
                              v-if="findIfActive('propertyType') == 1"
                              @select="checkForAllPropertyType"
                              @input="addToQuery('type', $event)"
                              :placeholder="'Property Type'"
                              :multiple="true"
                              v-model="types"
                              select-label=""
                              deselectLabel=""
                              selectedLabel=""
                              :options="[
                                { label: 'Bungalow', value: 'bungalow' },
                                { label: 'Terraced', value: 'terraced' },
                                { label: 'Detached', value: 'detached' },
                                {
                                  label: 'Semi-Detached',
                                  value: 'semi-detached',
                                },
                                { label: 'Flat', value: 'flat' },
                                { label: 'Land', value: 'land' },
                                { label: 'All', value: 'all' },
                              ]"
                              :close-on-select="false"
                              :clear-on-select="false"
                              :preserve-search="true"
                              label="label"
                              track-by="value"
                            >
                              <template
                                slot="selection"
                                slot-scope="{ values, isOpen }"
                                ><span
                                  class="multiselect__single"
                                  v-if="values.length &amp;&amp; !isOpen"
                                  >{{ values.length }} option{{
                                    values.length > 1 ? "s" : ""
                                  }}
                                  selected</span
                                ></template
                              >
                              <template #tag><p class="d-none"></p> </template>
                            </multiselect>
                          </div>
                          <div
                            style="margin-right: 15px !important"
                            class="rld-single-select mrr-10 w-153"
                          >
                            <multiselect
                              :searchable="false"
                              v-if="findIfActive('noOfBedrooms') == 1"
                              @input="
                                addToQuery(
                                  'bedrooms',
                                  _.get($event, 'value', '')
                                )
                              "
                              :placeholder="'Bedrooms'"
                              v-model="noOfBedrooms"
                              :options="[
                                { label: '1', value: '1' },
                                { label: ' 2', value: '2' },
                                { label: '3', value: '3' },
                                {
                                  label: '4',
                                  value: '4',
                                },
                                { label: '5', value: '5' },
                                { label: '6', value: '6' },
                                { label: '7', value: '7' },
                                { label: '8', value: '8' },
                                { label: '9', value: '9' },
                                { label: '10', value: '10' },
                                { label: '11', value: '11' },
                                { label: '12+', value: '12' },
                              ]"
                              select-label=""
                              deselectLabel=""
                              selectedLabel=""
                              :clear-on-select="false"
                              :preserve-search="true"
                              label="label"
                              track-by="value"
                            >
                              <template
                                slot="selection"
                                slot-scope="{ values, isOpen }"
                                ><span
                                  class="multiselect__single"
                                  v-if="values.length &amp;&amp; !isOpen"
                                  >{{ values.length }} option{{
                                    values.length > 1 ? "s" : ""
                                  }}
                                  selected</span
                                ></template
                              >
                              <template #tag><p class="d-none"></p> </template>
                            </multiselect>
                          </div>
                          <div class="col-md-2 pl-0 pr-0">
                            <button
                              style="
                                border-color: #548f4d !important;
                                color: White !important;
                              "
                              @click="toggle"
                              class="
                                btn btn-yellow
                                res-full-btn-width res-search-button-listing
                              "
                            >
                              Advanced
                            </button>
                          </div>
                          <p style="margin-top: auto">
                            <a
                              style="
                                color: #548f4d;
                                font-size: 16px;
                                font-weight: 600;
                                text-decoration: none;
                              "
                              href="javascript:void(0)"
                              @click="clear"
                            >
                              Clear Filter
                            </a>
                          </p>
                        </div>
                        <div style="padding-top: 20px">
                          <div class="row">
                            <div
                              style="padding-left: 1px"
                              class="rld-single-input res-mpw"
                            >
                              <input
                                class="ml-2 res_remove_ml w-115 res-search-1"
                                type="text"
                                placeholder="Min. £"
                                v-model="value[0]"
                                @change="
                                  addToQuery('priceFrom', $event.target.value)
                                "
                              />
                            </div>

                            <div style="width: 42%">
                              <vue-range-slider
                                :tooltip="false"
                                :min="0"
                                :max="10000000"
                                @slide-end="addToQuery('price', $event)"
                                :tooltip-dir="'bottom'"
                                :tooltip-style="{
                                  'background-color': '#3e4452',
                                  'border-color': '#3e4452',
                                }"
                                :process-style="{
                                  'background-color': '#3e4452',
                                }"
                                ref="slider"
                                v-model="value"
                              >
                                <template slot="tooltip" slot-scope="{ value }">
                                  <div class="diy-tooltip">
                                    {{ formatter(value) }}
                                  </div>
                                </template>
                              </vue-range-slider>
                            </div>
                            <div
                              style="padding-left: 1px"
                              class="rld-single-input res-mpw"
                            >
                              <input
                                class="ml-2 res_remove_ml w-115 res-search-1"
                                type="text"
                                placeholder="Max. £"
                                v-model="value[1]"
                                @change="
                                  addToQuery('priceTo', $event.target.value)
                                "
                              />
                            </div>
                            <div class="col pl-0 pr-0">
                              <button
                                style="width: 100%"
                                @click="search"
                                class="
                                  btn btn-search-new
                                  res-full-btn-width res-search-button-listing
                                "
                              >
                                Search
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="tabs_2">
                    <div class="rld-main-search">
                      <div
                        class="row"
                        style="margin-left: -21px; margin-right: -21px"
                      >
                        <div style="padding-left: 1px" class="rld-single-input">
                          <input
                            style="width: 210px"
                            class="ml-2 res_remove_ml res-postal-code"
                            type="text"
                            placeholder="Postcode/Town"
                            @change="
                              addToQuery('postCode', $event.target.value)
                            "
                          />
                        </div>
                        <div class="rld-single-select">
                          <multiselect
                            :searchable="false"
                            v-if="findIfActive('location') == 1"
                            @input="
                              addToQuery('radius', _.get($event, 'value', ''))
                            "
                            :placeholder="'Search Radius'"
                            v-model="radiusOptions"
                            select-label=""
                            deselectLabel=""
                            selectedLabel=""
                            :options="[
                              { label: 'Within 1/4 mile', value: '1/4' },
                              { label: ' Within 1/2 mile', value: '1/2' },
                              { label: 'Within 3 miles', value: '3' },
                              {
                                label: 'Within 5 miles',
                                value: '5',
                              },
                              { label: 'Within 10 miles', value: '10' },
                              { label: 'Within 15 miles', value: '15' },
                              { label: 'Within 20 miles', value: '20' },
                              { label: 'Within 30 miles', value: '30' },
                              { label: 'Within 40 miles', value: '40' },
                            ]"
                            :close-on-select="false"
                            :clear-on-select="false"
                            :preserve-search="true"
                            label="label"
                            track-by="value"
                          >
                            <template
                              slot="selection"
                              slot-scope="{ values, isOpen }"
                              ><span
                                class="multiselect__single"
                                v-if="values.length &amp;&amp; !isOpen"
                                >{{ values.length }} option{{
                                  values.length > 1 ? "s" : ""
                                }}
                                selected</span
                              ></template
                            >
                            <template #tag><p class="d-none"></p> </template>
                          </multiselect>
                        </div>
                        <div class="rld-single-select">
                          <multiselect
                            :searchable="false"
                            ref="multiselect"
                            v-if="findIfActive('propertyType') == 1"
                            @select="checkForAllPropertyType"
                            @input="addToQuery('type', $event)"
                            :placeholder="'Property Type'"
                            :multiple="true"
                            v-model="types"
                            select-label=""
                            deselectLabel=""
                            selectedLabel=""
                            :options="[
                              { label: 'Bungalow', value: 'bungalow' },
                              { label: 'Terraced', value: 'terraced' },
                              { label: 'Detached', value: 'detached' },
                              {
                                label: 'Semi-Detached',
                                value: 'semi-detached',
                              },
                              { label: 'Flat', value: 'flat' },
                              { label: 'Land', value: 'land' },
                              { label: 'All', value: 'all' },
                            ]"
                            :close-on-select="false"
                            :clear-on-select="false"
                            :preserve-search="true"
                            label="label"
                            track-by="value"
                          >
                            <template
                              slot="selection"
                              slot-scope="{ values, isOpen }"
                              ><span
                                class="multiselect__single"
                                v-if="values.length &amp;&amp; !isOpen"
                                >{{ values.length }} option{{
                                  values.length > 1 ? "s" : ""
                                }}
                                selected</span
                              ></template
                            >
                            <template #tag><p class="d-none"></p> </template>
                          </multiselect>
                        </div>
                        <div data-v-2a183b29="" class="rld-single-select">
                          <span
                            class="res-bed-icon"
                            style="
                              position: absolute;
                              left: 58.9%;
                              z-index: 5;
                              top: 74px;
                            "
                            ><img
                              class="bedroomAdvance"
                              :src="
                                require('../assets/images/icons/Bed-Icon.webp')
                              "
                          /></span>
                          <multiselect
                            :searchable="false"
                            v-if="findIfActive('noOfBedrooms') == 1"
                            @input="
                              addToQuery('bedrooms', _.get($event, 'value', ''))
                            "
                            :placeholder="'Bedrooms'"
                            v-model="noOfBedrooms"
                            select-label=""
                            deselectLabel=""
                            selectedLabel=""
                            :options="[
                              { label: '1', value: '1' },
                              { label: ' 2', value: '2' },
                              { label: '3', value: '3' },
                              {
                                label: '4',
                                value: '4',
                              },
                              { label: '5', value: '5' },
                              { label: '6', value: '6' },
                              { label: '7', value: '7' },
                              { label: '8', value: '8' },
                              { label: '9', value: '9' },
                              { label: '10', value: '10' },
                              { label: '11', value: '11' },
                              { label: '12+', value: '12' },
                            ]"
                            :clear-on-select="false"
                            :preserve-search="true"
                            label="label"
                            track-by="value"
                          >
                            <template
                              slot="selection"
                              slot-scope="{ values, isOpen }"
                              ><span
                                class="multiselect__single"
                                v-if="values.length &amp;&amp; !isOpen"
                                >{{ values.length }} option{{
                                  values.length > 1 ? "s" : ""
                                }}
                                selected</span
                              ></template
                            >
                            <template #tag><p class="d-none"></p> </template>
                          </multiselect>
                        </div>

                        <div class="dropdown-filter res-threedot">
                          <span
                            @click="checkForChange"
                            class="advanceSlow res-dots"
                            ><i class="fa fa-ellipsis-v"></i
                          ></span>
                        </div>
                        <div class="col-md-2 pl-0 pr-0">
                          <button
                            style="
                              border-color: #548f4d !important;
                              color: White !important;
                            "
                            @click="search"
                            class="btn btn-yellow"
                          >
                            Search Now
                          </button>
                        </div>
                        <div
                          :style="`
                                  width: 100%;
                                  padding:0;
                                  box-shadow: rgb(255 255 255 / 20%) 0px 0px 0px 9px;
                                  
                                  border-top-left-radius: 0px;
                                  border-top-right-radius: 0px;
                                  border-bottom-left-radius: 5px;
                                  border-bottom-right-radius: 5px;
                                  `"
                          class="explore__form-checkbox-list full-filter"
                        >
                          <div
                            class="inside-advance"
                            style="padding: 30px 30px 20px 30px"
                          >
                            <div
                              class="
                                col-lg-12 col-md-12 col-sm-12
                                py-1
                                pr-30
                                mr-5
                                sld
                              "
                            >
                              <div class="main-search-field-2">
                                <div
                                  v-if="findIfActive('priceRange') == 1"
                                  class="range-slider"
                                >
                                  <label
                                    >Price Range: £{{ formatter(value[0]) }} to
                                    £{{ formatter(value[1]) }}</label
                                  >
                                  <div class="clearfix"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12 p-0 mt-4 mb-4">
                            <div
                              class="
                                display
                                checkboxes
                                one-in-row
                                margin-bottom-10
                                ch-1
                                mr-2
                              "
                            >
                              <div class="col pr-0 res-pl-0">
                                <div class="form-group bath remove-margin">
                                  <span
                                    class="res-bathroom-icon"
                                    style="
                                      position: absolute;
                                      left: 16%;
                                      z-index: 5;
                                      top: 14px;
                                    "
                                    ><img
                                      class="bedroomAdvance"
                                      :src="
                                        require('../assets/images/icons/Bathroom-Icon.webp')
                                      "
                                  /></span>
                                  <multiselect
                                    :searchable="false"
                                    v-if="findIfActive('noOfBathrooms') == 1"
                                    @input="
                                      addToQuery(
                                        'bathrooms',
                                        _.get($event, 'value', '')
                                      )
                                    "
                                    :placeholder="'Bathrooms'"
                                    v-model="noOfBathrooms"
                                    :options="[
                                      { label: '1', value: '1' },
                                      { label: ' 2', value: '2' },
                                      { label: '3', value: '3' },
                                      {
                                        label: '4',
                                        value: '4',
                                      },
                                      { label: '5', value: '5' },
                                      { label: '6', value: '6' },
                                      { label: '7', value: '7' },
                                      { label: '8', value: '8' },
                                      { label: '9', value: '9' },
                                      { label: '10', value: '10' },
                                      { label: '11', value: '11' },
                                      { label: '12+', value: '12' },
                                    ]"
                                    select-label=""
                                    deselectLabel=""
                                    selectedLabel=""
                                    :clear-on-select="false"
                                    :preserve-search="true"
                                    label="label"
                                    track-by="value"
                                  >
                                    <template
                                      slot="selection"
                                      slot-scope="{ values, isOpen }"
                                      ><span
                                        class="multiselect__single"
                                        v-if="values.length &amp;&amp; !isOpen"
                                        >{{ values.length }} option{{
                                          values.length > 1 ? "s" : ""
                                        }}
                                        selected</span
                                      ></template
                                    >
                                    <template #tag
                                      ><p class="d-none"></p>
                                    </template>
                                  </multiselect>
                                </div>
                              </div>
                              <div class="col pl-0 pr-0">
                                <div class="form-group bath remove-margin">
                                  <span
                                    style="
                                      position: absolute;
                                      left: 10%;
                                      z-index: 5;
                                      top: 14px;
                                      margin-right: 40px;
                                    "
                                    ><img
                                      class="bedroomAdvance"
                                      :src="
                                        require('../assets/images/icons/Garage-Icon.webp')
                                      "
                                  /></span>
                                  <multiselect
                                    :searchable="false"
                                    v-if="findIfActive('garage') == 1"
                                    @input="addToQuery('garage', $event)"
                                    :placeholder="'Garage'"
                                    @select="checkForAllGarage"
                                    :multiple="true"
                                    v-model="garageOptions"
                                    select-label=""
                                    deselectLabel=""
                                    selectedLabel=""
                                    :options="[
                                      {
                                        label: 'Single',
                                        value: 'single',
                                      },
                                      {
                                        label: 'Double',
                                        value: 'double',
                                      },
                                      { label: 'Other', value: 'other' },
                                      { label: 'None', value: 'none' },
                                      { label: 'All', value: 'all' },
                                    ]"
                                    :close-on-select="false"
                                    :clear-on-select="false"
                                    :preserve-search="true"
                                    label="label"
                                    track-by="value"
                                  >
                                    <template
                                      slot="selection"
                                      slot-scope="{ values, isOpen }"
                                      ><span
                                        class="multiselect__single"
                                        v-if="values.length &amp;&amp; !isOpen"
                                        >{{ values.length }} option{{
                                          values.length > 1 ? "s" : ""
                                        }}
                                        selected</span
                                      ></template
                                    >
                                    <template #tag
                                      ><p class="d-none"></p>
                                    </template>
                                  </multiselect>
                                </div>
                              </div>
                              <div class="col pl-0 pr-0">
                                <div class="form-group bath remove-margin">
                                  <span
                                    class="res-parking-icon"
                                    style="
                                      position: absolute;
                                      left: 11%;
                                      z-index: 5;
                                      top: 15px;
                                    "
                                    ><img
                                      class="bedroomAdvance"
                                      :src="
                                        require('../assets/images/icons/Parking-Icon.webp')
                                      "
                                  /></span>
                                  <multiselect
                                    :searchable="false"
                                    v-if="findIfActive('parking') == 1"
                                    @input="addToQuery('parking', $event)"
                                    :placeholder="'Parking'"
                                    :multiple="true"
                                    @select="checkForAllParking"
                                    v-model="parkingOptions"
                                    :options="[
                                      {
                                        label: 'Off Street',
                                        value: 'offStreet',
                                      },
                                      {
                                        label: 'On Street',
                                        value: 'onStreet',
                                      },
                                      {
                                        label: 'Allocated',
                                        value: 'allocated',
                                      },
                                      {
                                        label: 'Permitted',
                                        value: 'permitted',
                                      },
                                      { label: 'All', value: 'all' },
                                    ]"
                                    select-label=""
                                    deselectLabel=""
                                    selectedLabel=""
                                    :close-on-select="false"
                                    :clear-on-select="false"
                                    :preserve-search="true"
                                    label="label"
                                    track-by="value"
                                  >
                                    <template
                                      slot="selection"
                                      slot-scope="{ values, isOpen }"
                                      ><span
                                        class="multiselect__single"
                                        v-if="values.length &amp;&amp; !isOpen"
                                        >{{ values.length }} option{{
                                          values.length > 1 ? "s" : ""
                                        }}
                                        selected</span
                                      ></template
                                    >
                                    <template #tag
                                      ><p class="d-none"></p>
                                    </template>
                                  </multiselect>
                                </div>
                              </div>
                              <div class="col pr-0 res-pl-0">
                                <div class="form-group bath remove-margin">
                                  <span
                                    class="res-garden-icon"
                                    style="
                                      position: absolute;
                                      left: 17%;
                                      z-index: 5;
                                      top: 14px;
                                    "
                                    ><img
                                      class="bedroomAdvance"
                                      :src="
                                        require('../assets/images/icons/Garden-Icon.webp')
                                      "
                                  /></span>
                                  <multiselect
                                    :searchable="false"
                                    v-if="findIfActive('noOfBathrooms') == 1"
                                    @input="
                                      addToQuery(
                                        'garden',
                                        _.get($event, 'value', '')
                                      )
                                    "
                                    :placeholder="'Garden'"
                                    v-model="gardenOptions"
                                    :options="[
                                      { label: 'Yes', value: 'yes' },
                                      { label: ' No', value: 'no' },
                                    ]"
                                    select-label=""
                                    deselectLabel=""
                                    selectedLabel=""
                                    :close-on-select="false"
                                    :clear-on-select="false"
                                    :preserve-search="true"
                                    label="label"
                                    track-by="value"
                                  >
                                    <template
                                      slot="selection"
                                      slot-scope="{ values, isOpen }"
                                      ><span
                                        class="multiselect__single"
                                        v-if="values.length &amp;&amp; !isOpen"
                                        >{{ values.length }} option{{
                                          values.length > 1 ? "s" : ""
                                        }}
                                        selected</span
                                      ></template
                                    >
                                    <template #tag
                                      ><p class="d-none"></p>
                                    </template>
                                  </multiselect>
                                </div>
                              </div>
                              <div class="col pl-0 pr-0">
                                <div class="form-group bath remove-margin">
                                  <span
                                    class="res-swmin-icon"
                                    style="
                                      position: absolute;
                                      left: 11.5%;
                                      z-index: 5;
                                      top: 16px;
                                    "
                                    ><img
                                      class="bedroomAdvance"
                                      :src="
                                        require('../assets/images/icons/Swimming-Pool-Icon.webp')
                                      "
                                  /></span>
                                  <multiselect
                                    :searchable="false"
                                    v-if="findIfActive('swimmingPool') == 1"
                                    @input="addToQuery('swimmingPool', $event)"
                                    :placeholder="'Swimming Pool'"
                                    :multiple="true"
                                    @select="checkForAllSwimmingPool"
                                    v-model="swimmingPoolOptions"
                                    select-label=""
                                    deselectLabel=""
                                    selectedLabel=""
                                    :options="[
                                      {
                                        label: 'Indoor',
                                        value: 'indoor',
                                      },
                                      {
                                        label: 'Outdoor',
                                        value: 'outdoor',
                                      },
                                      { label: 'None', value: 'none' },
                                      { label: 'All', value: 'all' },
                                    ]"
                                    :close-on-select="false"
                                    :clear-on-select="false"
                                    :preserve-search="true"
                                    label="label"
                                    track-by="value"
                                  >
                                    <template
                                      slot="selection"
                                      slot-scope="{ values, isOpen }"
                                      ><span
                                        class="multiselect__single"
                                        v-if="values.length &amp;&amp; !isOpen"
                                        >{{ values.length }} option{{
                                          values.length > 1 ? "s" : ""
                                        }}
                                        selected</span
                                      ></template
                                    >
                                    <template #tag
                                      ><p class="d-none"></p>
                                    </template>
                                  </multiselect>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-card>

          <!-- new checkup -->

          <div
            class="
              card
              mt-3
              mb-3
              res-remove-mt res-none
              for-selector-desktop
              opacity-to-zero
            "
          >
            <div
              style="
                padding-top: 20px;

                padding-bottom: 20px;
              "
            >
              <div style="padding-right: 0px" class="col-md-12">
                <div class="d-flex">
                  <div
                    data-v-2a183b29=""
                    class="rld-single-select mrr-10 w-100"
                  >
                    <multiselect
                      :searchable="false"
                      v-if="findIfActive('noOfBathrooms') == 1"
                      @input="
                        addToQuery('bedrooms', _.get($event, 'value', ''))
                      "
                      :placeholder="'Bathrooms'"
                      v-model="noOfBathrooms"
                      :options="[
                        { label: '1', value: '1' },
                        { label: ' 2', value: '2' },
                        { label: '3', value: '3' },
                        {
                          label: '4',
                          value: '4',
                        },
                        { label: '5', value: '5' },
                        { label: '6', value: '6' },
                        { label: '7', value: '7' },
                        { label: '8', value: '8' },
                        { label: '9', value: '9' },
                        { label: '10', value: '10' },
                        { label: '11', value: '11' },
                        { label: '12+', value: '12' },
                      ]"
                      select-label=""
                      deselectLabel=""
                      selectedLabel=""
                      :clear-on-select="false"
                      :preserve-search="true"
                      label="label"
                      track-by="value"
                    >
                      <template slot="selection" slot-scope="{ values, isOpen }"
                        ><span
                          class="multiselect__single"
                          v-if="values.length &amp;&amp; !isOpen"
                          >{{ values.length }} option{{
                            values.length > 1 ? "s" : ""
                          }}
                          selected</span
                        ></template
                      >
                      <template #tag><p class="d-none"></p> </template>
                    </multiselect>
                  </div>
                  <div
                    data-v-2a183b29=""
                    class="rld-single-select mrr-10 w-100"
                  >
                    <multiselect
                      :searchable="false"
                      v-if="findIfActive('garage') == 1"
                      @input="addToQuery('garage', _.get($event, 'value', ''))"
                      :placeholder="'Garage'"
                      :multiple="true"
                      @select="checkForAllGarage"
                      v-model="garageOptions"
                      :options="[
                        {
                          label: 'Single',
                          value: 'single',
                        },
                        {
                          label: 'Double',
                          value: 'double',
                        },
                        { label: 'Other', value: 'other' },
                        { label: 'None', value: 'none' },
                        { label: 'All', value: 'all' },
                      ]"
                      select-label=""
                      deselectLabel=""
                      selectedLabel=""
                      :close-on-select="false"
                      :clear-on-select="false"
                      :preserve-search="true"
                      label="label"
                      track-by="value"
                    >
                      <template slot="selection" slot-scope="{ values, isOpen }"
                        ><span
                          class="multiselect__single"
                          v-if="values.length &amp;&amp; !isOpen"
                          >{{ values.length }} option{{
                            values.length > 1 ? "s" : ""
                          }}
                          selected</span
                        ></template
                      >
                      <template #tag><p class="d-none"></p> </template>
                    </multiselect>
                  </div>
                  <div
                    data-v-2a183b29=""
                    class="rld-single-select mrr-10 w-100"
                  >
                    <multiselect
                      :searchable="false"
                      v-if="findIfActive('parking') == 1"
                      @select="checkForAllParking"
                      @input="addToQuery('parking', _.get($event, 'value', ''))"
                      :placeholder="'Parking'"
                      v-model="parkingOptions"
                      :options="[
                        {
                          label: 'Off Street',
                          value: 'offStreet',
                        },
                        {
                          label: 'On Street',
                          value: 'onStreet',
                        },
                        {
                          label: 'Allocated',
                          value: 'allocated',
                        },
                        {
                          label: 'Permitted',
                          value: 'permitted',
                        },
                        { label: 'All', value: 'all' },
                      ]"
                      :multiple="true"
                      select-label=""
                      deselectLabel=""
                      selectedLabel=""
                      :close-on-select="false"
                      :clear-on-select="false"
                      :preserve-search="true"
                      label="label"
                      track-by="value"
                    >
                      <template slot="selection" slot-scope="{ values, isOpen }"
                        ><span
                          class="multiselect__single"
                          v-if="values.length &amp;&amp; !isOpen"
                          >{{ values.length }} option{{
                            values.length > 1 ? "s" : ""
                          }}
                          selected</span
                        ></template
                      >
                      <template #tag><p class="d-none"></p> </template>
                    </multiselect>
                  </div>

                  <!-- non. of cars here -->
                  <div
                    style="padding-left: 1px"
                    class="rld-single-input res-mpw"
                  >
                    <input
                      class="ml-2 res_remove_ml w-185 res-search-1"
                      type="text"
                      placeholder="No. of Cars"
                      v-model="noOfCars"
                      @change="addToQuery('noOfCars', $event.target.value)"
                    />
                  </div>

                  <div
                    data-v-2a183b29=""
                    class="rld-single-select mrr-10 w-100"
                  >
                    <multiselect
                      :searchable="false"
                      v-if="findIfActive('swimmingPool') == 1"
                      @input="
                        addToQuery('swimmingPool', _.get($event, 'value', ''))
                      "
                      :multiple="true"
                      :placeholder="'Swimming Pool'"
                      @select="checkForAllSwimmingPool"
                      v-model="swimmingPoolOptions"
                      :options="[
                        {
                          label: 'Indoor',
                          value: 'indoor',
                        },
                        {
                          label: 'Outdoor',
                          value: 'outdoor',
                        },
                        { label: 'None', value: 'none' },
                        { label: 'All', value: 'all' },
                      ]"
                      select-label=""
                      deselectLabel=""
                      selectedLabel=""
                      :close-on-select="false"
                      :clear-on-select="false"
                      :preserve-search="true"
                      label="label"
                      track-by="value"
                    >
                      <template slot="selection" slot-scope="{ values, isOpen }"
                        ><span
                          class="multiselect__single"
                          v-if="values.length &amp;&amp; !isOpen"
                          >{{ values.length }} option{{
                            values.length > 1 ? "s" : ""
                          }}
                          selected</span
                        ></template
                      >
                      <template #tag><p class="d-none"></p> </template>
                    </multiselect>
                  </div>
                </div>
              </div>

              <!-- secondpart starts from garden -->

              <div
                style="padding-right: 0px; padding-top: 20px"
                class="col-md-12"
              >
                <div class="d-flex">
                  <label class="mb-auto mr-3"> Garden </label>
                  <input
                    v-model="gardenChecked"
                    class="mb-auto mr-3"
                    type="checkbox"
                  />
                  <div
                    style="padding-left: 1px"
                    class="rld-single-input res-mpw"
                  >
                    <input
                      :disabled="!gardenChecked"
                      class="ml-2 res_remove_ml w-130 res-search-1"
                      type="text"
                      placeholder="Area"
                      v-model="area"
                      @change="addToQuery('garden', $event.target.value)"
                    />
                  </div>
                  <div
                    data-v-2a183b29=""
                    class="rld-single-select mrr-10 w-124"
                  >
                    <multiselect
                      :searchable="false"
                      :disabled="!gardenChecked"
                      @input="
                        addToQuery('gardenArea', _.get($event, 'value', ''))
                      "
                      :placeholder="'Unit'"
                      v-model="unit"
                      :options="[
                        { label: 'Acres', value: 'acres' },
                        { label: ' Sq. Meters', value: 'sqMeters' },
                      ]"
                      select-label=""
                      deselectLabel=""
                      selectedLabel=""
                      :close-on-select="false"
                      :clear-on-select="false"
                      :preserve-search="true"
                      label="label"
                      track-by="value"
                    >
                      <template slot="selection" slot-scope="{ values, isOpen }"
                        ><span
                          class="multiselect__single"
                          v-if="values.length &amp;&amp; !isOpen"
                          >{{ values.length }} option{{
                            values.length > 1 ? "s" : ""
                          }}
                          selected</span
                        ></template
                      >
                      <template #tag><p class="d-none"></p> </template>
                    </multiselect>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- end -->
          <b-card class="mt-3 mb-3 res-remove-mt res-none">
            <section ref="hello-world" class="hello-world pt-0">
              <div class="pro-wrapper">
                <div class="detail-wrapper-body">
                  <div class="listing-title-bar">
                    <div class="text-heading text-left">
                      <p class="font-weight-bold mb-0 mt-3">
                        {{
                          `${allProperties.length} Search result${
                            allProperties.length > 1 ? "s" : ""
                          }`
                        }}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  class="
                    cod-pad
                    single
                    detail-wrapper
                    mr-2
                    mt-0
                    b-flex
                    justify-content-md-end
                    align-items-center
                  "
                >
                  <div
                    class="
                      wsset
                      input-group
                      border
                      rounded
                      input-group-lg
                      w-auto
                      mr-4
                    "
                  >
                    <label
                      class="
                        input-group-text
                        bg-transparent
                        border-0
                        text-uppercase
                        letter-spacing-093
                        pr-1
                        pl-3
                      "
                      for="inputGroupSelect01"
                      ><i class="fas fa-align-left fs-16 pr-2"></i>Per
                      Page:</label
                    >
                    <select
                      v-model="perPage"
                      style="top: -3px"
                      class="
                        pset
                        p0
                        form-control
                        border-0
                        bg-transparent
                        shadow-none
                        p-0
                        selectpicker
                        sortby
                      "
                      data-style="bg-transparent border-0 font-weight-600 btn-lg pl-0 pr-3"
                      id="inputGroupSelect01"
                      name="sortby"
                    >
                      <option selected value="10">10</option>
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option :value="total">All</option>
                    </select>
                  </div>
                  <div
                    class="
                      input-group
                      border
                      rounded
                      input-group-lg
                      g
                      w-auto
                      mr-4
                    "
                  >
                    <label
                      class="
                        input-group-text
                        gset
                        bg-transparent
                        border-0
                        text-uppercase
                        letter-spacing-093
                        pr-1
                        pl-3
                      "
                      for="inputGroupSelect01"
                      ><i class="fas fa-align-left fs-16 pr-2"></i
                      >Sortby:</label
                    >
                    <select
                      style="top: -3px"
                      class="
                        form-control
                        gset
                        border-0
                        bg-transparent
                        shadow-none
                        p-0
                        selectpicker
                        sortby
                      "
                      data-style="bg-transparent border-0 font-weight-600 btn-lg pl-0 pr-3"
                      id="inputGroupSelect01"
                      name="sortby"
                      v-model="sorting"
                    >
                      <option
                        @click="addToQuery('sort', 'price|asc')"
                        value="price|asc"
                      >
                        Price(low to high)
                      </option>
                      <option
                        @click="addToQuery('sort', 'price|desc')"
                        value="price|desc"
                      >
                        Price(high to low)
                      </option>
                      <option
                        @click="addToQuery('sort', 'id|desc')"
                        value="id|desc"
                      >
                        Newest
                      </option>
                      <option>Closest</option>
                    </select>
                  </div>
                </div>
              </div>
            </section>
          </b-card>

          <!-- mobile -->
          <div class="card mt-3 mb-3 res-remove-mt res-hide-mm">
            <div
              style="
                padding-top: 20px;

                padding-bottom: 20px;
              "
            >
              <div style="padding-right: 0px" class="col-md-12 pl-0">
                <div class="col d-flex">
                  <div
                    style="margin-right: 25px !important"
                    class="rld-single-input w-40 mrr-10"
                  >
                    <input
                      style="width: 210px"
                      class="ml-2 res_remove_ml res-search-1"
                      type="text"
                      placeholder="Postcode/Town"
                      v-model="keyword"
                      @change="addToQuery('postCode', $event.target.value)"
                    />
                  </div>
                  <div class="w-40">
                    <multiselect
                      :searchable="false"
                      v-if="findIfActive('location') == 1"
                      @input="addToQuery('radius', _.get($event, 'value', ''))"
                      :placeholder="'Distance'"
                      v-model="radiusOptions"
                      select-label=""
                      deselectLabel=""
                      selectedLabel=""
                      :options="[
                        { label: 'Within 1/4 mile', value: '1/4' },
                        { label: ' Within 1/2 mile', value: '1/2' },
                        { label: 'Within 3 miles', value: '3' },
                        {
                          label: 'Within 5 miles',
                          value: '5',
                        },
                        { label: 'Within 10 miles', value: '10' },
                        { label: 'Within 15 miles', value: '15' },
                        { label: 'Within 20 miles', value: '20' },
                        { label: 'Within 30 miles', value: '30' },
                        { label: 'Within 40 miles', value: '40' },
                      ]"
                      :clear-on-select="false"
                      :preserve-search="true"
                      label="label"
                      track-by="value"
                    >
                      <template slot="selection" slot-scope="{ values, isOpen }"
                        ><span
                          class="multiselect__single"
                          v-if="values.length &amp;&amp; !isOpen"
                          >{{ values.length }} option{{
                            values.length > 1 ? "s" : ""
                          }}
                          selected</span
                        ></template
                      >
                      <template #tag><p class="d-none"></p> </template>
                    </multiselect>
                  </div>
                </div>
                <div class="col d-flex">
                  <div
                    style="margin-right: 25px !important"
                    class="rld-single-input w-40 mrr-10"
                  >
                    <input
                      class="ml-2 res_remove_ml res-search-1"
                      type="text"
                      placeholder="Min. Price"
                      v-model="value[0]"
                      @change="addToQuery('priceFrom', $event.target.value)"
                    />
                  </div>
                  <div class="rld-single-input w-40 mrr-10">
                    <input
                      class="ml-2 res_remove_ml res-search-1"
                      type="text"
                      placeholder="Max. Price"
                      v-model="value[1]"
                      @change="addToQuery('priceTo', $event.target.value)"
                    />
                  </div>
                </div>
                <div class="col d-flex">
                  <div
                    style="margin-right: 25px !important"
                    class="rld-single-input w-40 mrr-10"
                  >
                    <button
                      style="
                        border-color: #3e4452 !important;
                        color: White !important;
                        width: 100%;
                      "
                      class="
                        btn btn-yellow
                        opacity-to-one
                        advance-open-then-hide
                      "
                      @click="toggleMobile"
                    >
                      Advanced
                    </button>
                  </div>
                  <div class="rld-single-input w-40 mrr-10">
                    <button
                      style="
                        border-color: #548f4d !important;
                        color: White !important;
                        width: 100%;
                      "
                      @click="search"
                      class="
                        btn btn-yellow
                        opacity-to-one
                        advance-open-then-hide
                      "
                    >
                      Search Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- second section on mobile -->

          <div
            class="
              card
              mt-3
              mb-3
              res-remove-mt res-hide-mm
              for-selector-mobile
              opacity-to-zero
            "
          >
            <div
              style="
                padding-top: 20px;

                padding-bottom: 20px;
              "
            >
              <div style="padding-right: 0px" class="col-md-12 pl-0">
                <div class="col">
                  <multiselect
                    :searchable="false"
                    ref="multiselect"
                    v-if="findIfActive('propertyType') == 1"
                    @input="addToQuery('type', $event)"
                    :placeholder="'Property Type'"
                    :multiple="true"
                    @select="checkForAllPropertyType"
                    v-model="types"
                    select-label=""
                    deselectLabel=""
                    selectedLabel=""
                    :options="[
                      { label: 'Bungalow', value: 'bungalow' },
                      { label: 'Terraced', value: 'terraced' },
                      { label: 'Detached', value: 'detached' },
                      {
                        label: 'Semi-Detached',
                        value: 'semi-detached',
                      },
                      { label: 'Flat', value: 'flat' },
                      { label: 'Land', value: 'land' },
                      { label: 'All', value: 'all' },
                    ]"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    label="label"
                    track-by="value"
                  >
                    <template slot="selection" slot-scope="{ values, isOpen }"
                      ><span
                        class="multiselect__single"
                        v-if="values.length &amp;&amp; !isOpen"
                        >{{ values.length }} option{{
                          values.length > 1 ? "s" : ""
                        }}
                        selected</span
                      ></template
                    >
                    <template #tag><p class="d-none"></p> </template>
                  </multiselect>
                </div>
                <div class="col">
                  <multiselect
                    :searchable="false"
                    v-if="findIfActive('noOfBedrooms') == 1"
                    @input="addToQuery('bedrooms', _.get($event, 'value', ''))"
                    :placeholder="'Bedrooms'"
                    v-model="noOfBedrooms"
                    :options="[
                      { label: '1', value: '1' },
                      { label: ' 2', value: '2' },
                      { label: '3', value: '3' },
                      {
                        label: '4',
                        value: '4',
                      },
                      { label: '5', value: '5' },
                      { label: '6', value: '6' },
                      { label: '7', value: '7' },
                      { label: '8', value: '8' },
                      { label: '9', value: '9' },
                      { label: '10', value: '10' },
                      { label: '11', value: '11' },
                      { label: '12+', value: '12' },
                    ]"
                    select-label=""
                    deselectLabel=""
                    selectedLabel=""
                    :clear-on-select="false"
                    :preserve-search="true"
                    label="label"
                    track-by="value"
                  >
                    <template slot="selection" slot-scope="{ values, isOpen }"
                      ><span
                        class="multiselect__single"
                        v-if="values.length &amp;&amp; !isOpen"
                        >{{ values.length }} option{{
                          values.length > 1 ? "s" : ""
                        }}
                        selected</span
                      ></template
                    >
                    <template #tag><p class="d-none"></p> </template>
                  </multiselect>
                </div>
                <div class="col">
                  <multiselect
                    :searchable="false"
                    v-if="findIfActive('noOfBathrooms') == 1"
                    @input="addToQuery('bathrooms', _.get($event, 'value', ''))"
                    :placeholder="'Bathrooms'"
                    v-model="noOfBathrooms"
                    :options="[
                      { label: '1', value: '1' },
                      { label: ' 2', value: '2' },
                      { label: '3', value: '3' },
                      {
                        label: '4',
                        value: '4',
                      },
                      { label: '5', value: '5' },
                      { label: '6', value: '6' },
                      { label: '7', value: '7' },
                      { label: '8', value: '8' },
                      { label: '9', value: '9' },
                      { label: '10', value: '10' },
                      { label: '11', value: '11' },
                      { label: '12+', value: '12' },
                    ]"
                    select-label=""
                    deselectLabel=""
                    selectedLabel=""
                    :clear-on-select="false"
                    :preserve-search="true"
                    label="label"
                    track-by="value"
                  >
                    <template slot="selection" slot-scope="{ values, isOpen }"
                      ><span
                        class="multiselect__single"
                        v-if="values.length &amp;&amp; !isOpen"
                        >{{ values.length }} option{{
                          values.length > 1 ? "s" : ""
                        }}
                        selected</span
                      ></template
                    >
                    <template #tag><p class="d-none"></p> </template>
                  </multiselect>
                </div>

                <div class="col">
                  <multiselect
                    :searchable="false"
                    v-if="findIfActive('swimmingPool') == 1"
                    @input="addToQuery('swimmingPool', $event)"
                    :placeholder="'Swimming Pool'"
                    :multiple="true"
                    @select="checkForAllSwimmingPool"
                    v-model="swimmingPoolOptions"
                    select-label=""
                    deselectLabel=""
                    selectedLabel=""
                    :options="[
                      {
                        label: 'Indoor',
                        value: 'indoor',
                      },
                      {
                        label: 'Outdoor',
                        value: 'outdoor',
                      },
                      { label: 'None', value: 'none' },
                      { label: 'All', value: 'all' },
                    ]"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    label="label"
                    track-by="value"
                  >
                    <template slot="selection" slot-scope="{ values, isOpen }"
                      ><span
                        class="multiselect__single"
                        v-if="values.length &amp;&amp; !isOpen"
                        >{{ values.length }} option{{
                          values.length > 1 ? "s" : ""
                        }}
                        selected</span
                      ></template
                    >
                    <template #tag><p class="d-none"></p> </template>
                  </multiselect>
                </div>
                <hr />
                <div class="col">
                  <multiselect
                    :searchable="false"
                    v-if="findIfActive('garage') == 1"
                    @input="addToQuery('garage', $event)"
                    :placeholder="'Garage'"
                    :multiple="true"
                    @select="checkForAllGarage"
                    v-model="garageOptions"
                    select-label=""
                    deselectLabel=""
                    selectedLabel=""
                    :options="[
                      {
                        label: 'Single',
                        value: 'single',
                      },
                      {
                        label: 'Double',
                        value: 'double',
                      },
                      { label: 'Other', value: 'other' },
                      { label: 'None', value: 'none' },
                      { label: 'All', value: 'all' },
                    ]"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    label="label"
                    track-by="value"
                  >
                    <template slot="selection" slot-scope="{ values, isOpen }"
                      ><span
                        class="multiselect__single"
                        v-if="values.length &amp;&amp; !isOpen"
                        >{{ values.length }} option{{
                          values.length > 1 ? "s" : ""
                        }}
                        selected</span
                      ></template
                    >
                    <template #tag><p class="d-none"></p> </template>
                  </multiselect>
                </div>
                <div class="col">
                  <multiselect
                    :searchable="false"
                    v-if="findIfActive('parking') == 1"
                    @input="addToQuery('parking', $event)"
                    @select="checkForAllParking"
                    :placeholder="'Parking'"
                    :multiple="true"
                    v-model="parkingOptions"
                    :options="[
                      {
                        label: 'Off Street',
                        value: 'offStreet',
                      },
                      {
                        label: 'On Street',
                        value: 'onStreet',
                      },
                      {
                        label: 'Allocated',
                        value: 'allocated',
                      },
                      {
                        label: 'Permitted',
                        value: 'permitted',
                      },
                      { label: 'All', value: 'all' },
                    ]"
                    select-label=""
                    deselectLabel=""
                    selectedLabel=""
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    label="label"
                    track-by="value"
                  >
                    <template slot="selection" slot-scope="{ values, isOpen }"
                      ><span
                        class="multiselect__single"
                        v-if="values.length &amp;&amp; !isOpen"
                        >{{ values.length }} option{{
                          values.length > 1 ? "s" : ""
                        }}
                        selected</span
                      ></template
                    >
                    <template #tag><p class="d-none"></p> </template>
                  </multiselect>
                </div>
                <div class="col">
                  <input
                    class="ml-2 res_remove_ml w-185 res-search-1 noOf"
                    type="text"
                    placeholder="No. of Cars"
                    v-model="noOfCars"
                    @change="addToQuery('noOfCars', $event.target.value)"
                  />
                </div>
                <hr />
                <div class="col">
                  <label class="mb-auto mr-3"> Garden </label>
                  <input
                    v-model="gardenChecked"
                    class="mb-auto mr-3"
                    type="checkbox"
                  />
                </div>
                <div class="col d-flex mt-3">
                  <div
                    style="margin-right: 25px !important"
                    class="rld-single-input w-40 mrr-10"
                  >
                    <input
                      :disabled="!gardenChecked"
                      style="width: 210px"
                      class="ml-2 res_remove_ml res-search-1"
                      type="text"
                      placeholder="Area"
                      v-model="area"
                      @change="addToQuery('garden', $event.target.value)"
                    />
                  </div>
                  <div style="border-radius: 8px" class="w-40 res-mpw mrr-10">
                    <multiselect
                      :searchable="false"
                      :disabled="!gardenChecked"
                      @input="
                        addToQuery('gardenArea', _.get($event, 'value', ''))
                      "
                      :placeholder="'Unit'"
                      v-model="unit"
                      :options="[
                        { label: 'Acres', value: 'acres' },
                        { label: ' Sq. Meters', value: 'sqMeters' },
                      ]"
                      select-label=""
                      deselectLabel=""
                      selectedLabel=""
                      :close-on-select="false"
                      :clear-on-select="false"
                      :preserve-search="true"
                      label="label"
                      track-by="value"
                    >
                      <template slot="selection" slot-scope="{ values, isOpen }"
                        ><span
                          class="multiselect__single"
                          v-if="values.length &amp;&amp; !isOpen"
                          >{{ values.length }} option{{
                            values.length > 1 ? "s" : ""
                          }}
                          selected</span
                        ></template
                      >
                      <template #tag><p class="d-none"></p> </template>
                    </multiselect>
                  </div>
                </div>
                <div class="col">
                  <button
                    style="
                      border-color: #548f4d !important;
                      color: White !important;
                      width: 100%;
                    "
                    @click="toggleMobile"
                    class="btn btn-yellow"
                  >
                    Hide Advanced
                  </button>
                </div>
                <div class="col">
                  <button
                    style="
                      border-color: #548f4d !important;
                      color: White !important;
                      width: 100%;
                    "
                    @click="search"
                    class="btn btn-yellow"
                  >
                    Search Now
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="row featured portfolio-items h-auto">
            <simple-list-view
              @pageChanged="changePage($event)"
              :processing="processing"
              :search.sync="keyword"
              :sorting="sorting"
              :selectPerPage="perPage"
              :allMessages="allMessages"
              :properties="allProperties"
              :is="currentTabComponent"
              :paginationData="paginationData"
              @busy="isBusy"
            ></simple-list-view>
          </div>
        </div>
      </section>
      <a data-scroll href="#wrapper" class="go-up" style="display: inline"
        ><i class="fa fa-angle-double-up" aria-hidden="true"></i
      ></a>
    </div>
  </div>
</template>

<script>
const simpleListView = () =>
  import(/* webpackChunkName: "af92bUksAE" */ "../components/simple-list-view");
import "vue-range-component-fixed/dist/vue-range-slider.css";
import VueRangeSlider from "vue-range-component-fixed";
import { mapGetters } from "vuex";
import _ from "lodash";
import { formatter } from "../utils";
import Multiselect from "vue-multiselect";
import $ from "jquery";

export default {
  data() {
    return {
      gardenChecked: false,
      unit: "",
      area: "",
      noOfCars: "",
      radiusEnums: {
        "1/4": { label: "Within 1/4 mile", value: "1/4" },
        "1/2": { label: " Within 1/2 mile", value: "1/2" },
        3: { label: "Within 3 miles", value: "3" },
        5: {
          label: "Within 5 miles",
          value: "5",
        },
        10: { label: "Within 10 miles", value: "10" },
        15: { label: "Within 15 miles", value: "15" },
        20: { label: "Within 20 miles", value: "20" },
        30: { label: "Within 30 miles", value: "30" },
        40: { label: "Within 40 miles", value: "40" },
      },
      searchOpen: false,
      canBeMultiple: ["type", "garage", "parking", "swimmingPool"],
      gardenOptions: [],
      noOfBathrooms: [],
      noOfBedrooms: [],
      radiusOptions: "",
      swimmingPoolOptions: [],
      parkingOptions: [],
      garageOptions: [],
      types: [],
      isBusy: true,
      rangeValues: [
        0, 50000, 60000, 70000, 80000, 90000, 100000, 120000, 140000, 160000,
        180000, 200000, 220000, 240000, 260000, 280000, 300000, 320000, 340000,
        360000, 380000, 400000, 420000, 440000, 460000, 480000, 500000, 600000,
        700000, 800000, 900000, 1000000, 1250000, 1500000, 1750000, 2000000,
        2250000, 2500000, 2750000, 3000000, 3250000, 3500000, 3750000, 4000000,
        4250000, 4500000, 4750000, 5000000, 6000000, 7000000, 8000000, 9000000,
        10000000,
      ],
      processing: false,
      keyword: "",
      tenure: null,
      bathrooms: null,
      bedrooms: null,
      location: "1",
      type: "1",
      sorting: "price|desc",
      perPage: 10,
      paginationData: null,
      basement: false,
      query: {},
      value: ["", ""],
      keys: [],
      shouldSearch: false,
      currentTabComponent: "simpleListView",
      allMessages: [],
      messages: [],
      swimmingPool: false,
      garage: false,
      parking: "",
      garden: false,
      screenHeight: window.innerHeight,
      screenWidth: window.innerWidth,
    };
  },
  computed: {
    _() {
      return _;
    },
    ...mapGetters({
      properties: "getProperties",
      configurations: "getConfigurations",
      total: "getTotal",
    }),
    allProperties() {
      return this.properties.properties ? this.properties.properties : [];
    },
  },
  components: {
    simpleListView,
    VueRangeSlider,
    Multiselect,
  },
  methods: {
    checkForAllPropertyType(selectedItem) {
      this.$nextTick(() => {
        if (selectedItem.value == "all") {
          this.types = [
            { label: "Bungalow", value: "bungalow" },
            { label: "Terraced", value: "terraced" },
            { label: "Detached", value: "detached" },
            { label: "Semi-Detached", value: "semi-detached" },
            { label: "Flat", value: "flat" },
            { label: "Land", value: "land" },
          ];
        }
      });
    },
    checkForAllGarage(selectedItem) {
      this.$nextTick(() => {
        if (selectedItem.value == "all") {
          this.garageOptions = [
            { label: "Single", value: "single" },
            { label: "Double", value: "double" },
            { label: "Other", value: "other" },
          ];
        }
      });
    },
    checkForAllSwimmingPool(selectedItem) {
      this.$nextTick(() => {
        if (selectedItem.value == "all") {
          this.swimmingPoolOptions = [
            { label: "Indoor", value: "indoor" },
            { label: "Outdoor", value: "outdoor" },
          ];
        }
      });
    },
    checkForAllParking(selectedItem) {
      this.$nextTick(() => {
        if (selectedItem.value == "all") {
          this.parkingOptions = [
            { label: "On Street", value: "onStreet" },
            { label: "Allocated", value: "allocated" },
            { label: "Permitted", value: "permitted" },
            { label: "Off Street", value: "offStreet" },
          ];
        }
      });
    },
    toggle() {
      if (
        document
          .querySelector(".for-selector-desktop")
          .classList.contains("opacity-to-zero")
      ) {
        document
          .querySelector(".for-selector-desktop")
          .classList.remove("opacity-to-zero");
        document
          .querySelector(".for-selector-desktop")
          .classList.add("opacity-to-one");
      } else if (
        document
          .querySelector(".for-selector-desktop")
          .classList.contains("opacity-to-one")
      ) {
        document
          .querySelector(".for-selector-desktop")
          .classList.remove("opacity-to-one");
        document
          .querySelector(".for-selector-desktop")
          .classList.add("opacity-to-zero");
      }
    },
    toggleMobile() {
      if (
        document
          .querySelector(".for-selector-mobile")
          .classList.contains("opacity-to-zero")
      ) {
        document
          .querySelector(".for-selector-mobile")
          .classList.remove("opacity-to-zero");
        document
          .querySelector(".for-selector-mobile")
          .classList.add("opacity-to-one");
        document
          .querySelectorAll(".advance-open-then-hide")[0]
          .classList.remove("opacity-to-one");
        document
          .querySelectorAll(".advance-open-then-hide")[0]
          .classList.add("opacity-to-zero");
        document
          .querySelectorAll(".advance-open-then-hide")[1]
          .classList.remove("opacity-to-one");
        document
          .querySelectorAll(".advance-open-then-hide")[1]
          .classList.add("opacity-to-zero");
      } else if (
        document
          .querySelector(".for-selector-mobile")
          .classList.contains("opacity-to-one")
      ) {
        document
          .querySelector(".for-selector-mobile")
          .classList.remove("opacity-to-one");
        document
          .querySelector(".for-selector-mobile")
          .classList.add("opacity-to-zero");
        document
          .querySelectorAll(".advance-open-then-hide")[0]
          .classList.remove("opacity-to-zero");
        document
          .querySelectorAll(".advance-open-then-hide")[0]
          .classList.add("opacity-to-one");
        document
          .querySelectorAll(".advance-open-then-hide")[1]
          .classList.remove("opacity-to-zero");
        document
          .querySelectorAll(".advance-open-then-hide")[1]
          .classList.add("opacity-to-one");
      }
    },
    checkForChange() {
      this.isAdvancedSearchOpen();
    },
    resize() {
      if ($(window).width() < 700) {
        $(".checkboxes").removeClass("display");
      } else if ($(window).width() > 700) {
        $(".checkboxes").addClass("display");
      }
    },
    isAdvancedSearchOpen() {
      this.searchOpen = !document
        .querySelector(".full-filter")
        ?.classList.contains("filter-block");
    },
    formatter: formatter,
    findIfActive(config) {
      if (!_.isEmpty(this.configurations)) {
        let matchedConfig = this.configurations.find((configuration) => {
          return configuration.key == config;
        });
        return matchedConfig ? matchedConfig.val.isActive : 0;
      } else return 0;
    },
    clear() {
      this.keyword = "";
      this.radiusOptions = "";
      this.types = "";
      this.value = [0, 0];
      this.swimmingPool = "";
      this.garage = "";
      this.parking = "";
      this.noOfCars = "";
      this.area = "";
      this.garden = false;
      this.noOfBedrooms = "";
      this.noOfBathrooms = "";
      this.$router.push({ query: { sort: "price|desc" } });
    },
    changePage(page) {
      this.$router.push({ query: { ...this.query, page: page } });
    },
    search() {
      this.$router.push({ query: { ...this.query } });
      this.toggle();
      this.toggleMobile();
    },
    applyFilter(query) {
      this.processing = true;
      let queryString = "";
      let keys = Object.keys(query);
      for (let i = 0; i < keys.length; i++) {
        queryString =
          queryString + `${keys[i]}=${query[keys[i]]}${keys[i + 1] ? "&" : ""}`;
      }
      this.$store.dispatch("searchProperties", queryString).then((res) => {
        this.processing = false;
        if (res == true) {
          this.isBusy = false;
          this.paginationData = {
            currentPage: this.properties.currentPage,
            firstPageUrl: this.properties.firstPageUrl,
            from: this.properties.from,
            lastPage: this.properties.lastPage,
            lastPageUrl: this.properties.lastPageUrl,
            nextPageUrl: this.properties.nextPageUrl,
            perPage: this.properties.perPage,
            previousPageUrl: this.properties.previousPageUrl,
            total: this.properties.total,
          };
        }
      });
    },
    addToQuery(param, event) {
      if (typeof event == "object") {
        if (param == "price") {
          let obj = { priceFrom: "", priceTo: "" };
          for (let i = 0; i < Object.keys(obj).length; i++) {
            this.query[Object.keys(obj)[i]] = event[i];
          }
        } else if (this.canBeMultiple.includes(param)) {
          let queryString = "";
          for (let i = 0; i < event.length; i++) {
            queryString =
              queryString + `${event[i].value}${event[i + 1] ? "," : ""}`;
          }
          this.query[param] = queryString;
        }
        return;
      }
      this.query[param] = event;
    },
  },
  watch: {
    sorting(val) {
      this.query = { ...this.$route.query, sort: val };
      this.$router.push({ query: { ...this.query } });
    },
    $route({ query }) {
      this.query = { ...query };
      this.applyFilter(query);
    },
    perPage(val) {
      this.query = { ...this.$route.query };
      this.$router.push({ query: { ...this.query, perPage: val } });
    },
  },
  created() {
    if (!Object.keys(this.$route.query).length)
      this.$router.push({ query: { sort: "price|desc" } });
    this.query = { ...this.$route.query };
  },
  async mounted() {
    this.resize();
    if (Object.keys(this.$route.query).length) {
      this.applyFilter(this.$route.query);
      for (let key in this.$route.query) {
        if (key == "radius")
          this.radiusOptions = this.radiusEnums[this.$route.query[key]];
        if (key == "type") this.type = this.$route.query[key];
        if (key == "location") this.location = this.$route.query[key];
        if (key == "basement")
          this.basement = this.$route.query[key] == "yes" ? true : false;
        if (key == "swimmingPool")
          this.swimmingPool = this.$route.query[key] == "yes" ? true : false;
        if (key == "garage")
          this.garage = this.$route.query[key] == "yes" ? true : false;
        if (key == "garden")
          this.garden = this.$route.query[key] == "yes" ? true : false;
        if (key == "parking")
          this.parking = this.$route.query[key] == "yes" ? true : false;
        if (key == "bedrooms") this.bedrooms = this.$route.query[key];
        if (key == "bathrooms") this.bathrooms = this.$route.query[key];
        if (key == "tenure") this.tenure = this.$route.query[key];
        if (key == "postCode") this.keyword = this.$route.query[key];
        if (key == "price") this.value = this.$route.query[key];
        if (key == "sort") this.sorting = this.$route.query[key];
      }
    } else {
      this.paginationData = {
        currentPage: this.properties.currentPage,
        firstPageUrl: this.properties.firstPageUrl,
        from: this.properties.from,
        lastPage: this.properties.lastPage,
        lastPageUrl: this.properties.lastPageUrl,
        nextPageUrl: this.properties.nextPageUrl,
        perPage: this.properties.perPage,
        previousPageUrl: this.properties.previousPageUrl,
        total: this.properties.total,
      };
    }
    if (_.isEmpty(this.configurations)) {
      await this.$store.dispatch("getConfigurations");
    }
    this.configurations.forEach(
      (configuration) => (this.keys = [...this.keys, configuration.key])
    );
    this.$nextTick(() => {
      $(".dropdown-filter").on("click", function () {
        $(".explore__form-checkbox-list").toggleClass("filter-block");
      });
      document.querySelector("body").addEventListener("click", (e) => {
        let found = e.path.find((p) => {
          return p.classList ? p.classList?.contains("full-filter") : false;
        });
        if (
          !e.target.classList.contains("advanceSlows") &&
          document
            .querySelector(".full-filter")
            .classList.contains("filter-block") &&
          !found
        ) {
          e.stopPropagation();
          document.querySelector(".advanceSlows").click();
        }
      });
    });
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped src="../assets/css/fontawesome-all.css"></style>
<style scoped src="../assets/css/fontawesome-5-all.min.css"></style>
<style scoped src="../assets/css/font-awesome.min.css"></style>
<style scoped src="../assets/css/bootstrap.min.css"></style>
<style scoped src="../assets/css/styles.css"></style>

<style scoped>
div >>> .vue-range-slider {
  padding-top: 20px !important;
}

div >>> .multiselect--disabled {
  border-radius: 8px !important;
}

div >>> .multiselect__option--selected {
  background: #548f4d;
  color: white;
  font-weight: 500;
}

div >>> .multiselect__option--selected.multiselect__option--highlight {
  background: #548f4d;
  color: white;
  font-weight: 500;
}

div >>> .multiselect__option--highlight {
  background: white;
  color: #4f5f73;
}

.noOf {
  height: 48px;
  line-height: 48px;
  width: 100%;
  padding: 0 20px;
  border: 1px solid #d4d4d4;
  transition: all 0.4s ease;
  border-radius: 8px;
  background: #f7f7f7;
  font-size: 15px;
}
.w-100 {
  width: 100% !important;
}
.mb-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.w-130 {
  width: 130px !important;
}
.w-185 {
  width: 185px !important;
}
.w-124 {
  width: 225px !important;
}
.w-125 {
  width: 194px !important;
}
.w-122 {
  width: 225px !important;
}

.w-115 {
  width: 153px !important;
}
.rld-main-searches {
  padding: 0px 6px 20px 20px;
  border: 0px;
  width: 100%;
  box-shadow: none;
}

.btn-search-new {
  color: white !important;
  background: #548f4d !important;
  height: 49px;
  border-radius: 8px;
}

.btn-search-new:hover {
  color: white !important;
  background: #3e4452 !important;
}

.w-153 {
  width: 153px !important;
}
.mrr-10 {
  margin-right: 10px !important;
}

div >>> .multiselect__select {
  left: 74% !important;
  top: 13% !important;
  background: #f7f7f7;
}

div >>> .multiselect__tags {
  padding-left: 20px !important;
  margin-left: 0px !important;
  margin-right: 18px !important;
  width: 100% !important;
  max-width: 100% !important;
  background: #f7f7f7;
}

div >>> .multiselect__single {
  background: #f7f7f7;
}

div >>> .multiselect__input {
  background: #f7f7f7;
}
@media (min-width: 370px) and (max-width: 450px) {
  div >>> .multiselect__select {
    width: 25%;
  }
  .res-test-full {
    margin-right: -19px !important;
  }
}

@media (max-width: 767px) {
  div >>> .multiselect__select {
    width: 25%;
  }
  .mrr-10 {
    margin-right: 0px !important;
  }

  .w-40 {
    width: 50% !important;
  }
  .res-none {
    display: none !important;
  }
  .res-setBathroom {
    left: 1% !important;
  }
  .res-setGarage {
    left: 1% !important;
  }
  .w-124 {
    width: 100% !important;
  }
  .w-125 {
    width: 100% !important;
  }

  .res-mpw {
    margin-right: 0px !important;
    padding-right: 0px !important;
  }

  .res-search-button-listing {
    border-color: rgb(84, 143, 77) !important;
    color: white !important;

    margin-left: 0px !important;

    margin-right: 0px !important;

    width: 100% !important;
  }

  div >>> .multiselect__tags {
    padding-right: 0px;

    width: 100% !important;
    max-width: 100% !important;
    margin-bottom: 10px !important;

    padding-left: 20px !important;
    margin-left: 0px !important;
    margin-right: 18px !important;
  }

  .res-height-446 {
    height: 446px !important;
  }

  .res-full-btn-width {
    margin-left: 0px !important;

    margin-right: 0px !important;

    width: 100% !important;

    z-index: 1 !important;
    border-color: rgb(84, 143, 77) !important;
    color: white !important;
  }

  .hp-6 .btn.btn-yellow {
    margin-top: 15px !important;
  }
  .res-setParking {
    left: 1% !important;
  }

  .res-setGarden {
    left: 1% !important;
  }

  .res-remove-mt {
    margin-top: 0px !important;
  }

  .res-setSwim {
    left: 1% !important;
  }

  .res-test-full {
    padding-right: 9px !important;
    padding-left: 27px !important;
    box-shadow: none !important;
    margin-left: -19px !important;

    width: 123% !important;
  }

  .advanceSlow-new {
    padding-left: 77% !important;
    padding-right: 15% !important;
    background: white !important;
  }

  .inner-pages.hp-6.full .rld-main-search {
    padding-right: 20px;
  }
  .res-butt {
    display: none !important;
  }
  .res-p {
    padding-top: 187px;
  }
  .res-swinign {
    margin-left: 14px !important;
    width: 94% !important;
  }

  .res-s-dnone {
    display: none;
  }
  .res-bimg {
    background-size: 100% 268px !important;
    height: 795px !important;
  }

  .res_remove_ml {
    margin-left: 0px !important;
  }
  .res-width {
    width: 100% !important;
  }
  .float-rights {
    text-align: center !important;
    float: none !important;
  }
  .res-search-1 {
    width: 100% !important;
  }
  .res-dots {
    float: right !important;
    width: 100% !important;
    text-align: end;
    margin: 0px !important;
    padding: 0px !important;
  }

  .hp-6 .dropdown-filter span {
    width: 100% !important;
  }

  .pad-09-dots-icon {
    padding-right: 30px;
  }
  .property-margin {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .wide {
    width: 100% !important;
  }
  .res-plt {
    margin-top: 70px !important;
  }
  .res-button-how {
    margin-bottom: 10px !important;
    width: 250px !important;
    padding: 13px !important;
    margin-left: 20px !important;
  }

  .res-button-api {
    width: 320px !important;
    padding: 12px !important;
    margin: 13px !important;
  }

  div >>> .multiselect__tags {
    padding-right: 0px;

    width: 100% !important;
    max-width: 100% !important;
    margin-bottom: 10px !important;

    padding-left: 20px !important;
    margin-left: 0px !important;
    margin-right: 18px !important;
  }

  div >>> .multiselect {
    width: 100% !important;
    padding-left: 0px !important;
    margin: 0 !important;
  }

  .res-postal-code {
    margin-right: 0px !important;
  }
  .res-threedot {
    margin-left: 19px !important;
    width: 89.9% !important;
  }

  .res-margin-save-set {
    margin-top: 15% !important;
  }

  .sec-title {
    padding-bottom: 0px !important;
  }

  .res-mt-2 {
    margin-top: 40px !important;
  }

  .admin {
    display: none !important;
  }

  .res-threedot span {
    padding-right: 24px !important;
  }

  .res-bed-icon {
    position: absolute;
    top: 54% !important;
    left: 1% !important;
    z-index: 9999;
    top: 112px;
  }

  .res-bathroom-icon {
    left: 10% !important;
  }

  .res-garden-icon {
    left: 10% !important;
  }

  .res-parking-icon {
    left: 10% !important;
  }

  .res-swmin-icon {
    left: 10% !important;
  }

  .res-pl-0 {
    padding-left: 0px !important;
  }

  .res-button-prop {
    padding: 15px 35px !important;
  }
  .homepage-5 .info-help.h18 {
    padding-top: 63px !important;
    padding-bottom: 63px !important;
  }
  .res-display {
    display: none;
  }
}

@media (min-width: 300px) and (max-width: 700px) {
  .res-m-0 {
    margin-left: 0px !important;
  }
  .res-postal-code {
    width: 289px !important;
  }
  .res-m15 {
    margin-top: 15px !important;
  }
  .res-ml0 {
    margin-left: 0px !important;
  }
  .res-m-wm {
    margin-left: 0px !important;
    width: 100% !important;
  }

  .res-images-swiming {
    margin-left: 14px;
    width: 93% !important;
  }

  .res-icons-img {
    font-size: 12px !important;
    width: 12% !important;
  }
  .res-bb {
    margin-top: -70px !important;
    margin-left: 135px !important;
  }
  .wsset {
    margin-right: 0px !important;
    width: 100% !important;
  }
  .g {
    margin-right: 0px !important;
    width: 69% !important;
  }

  div >>> .multiselect__select {
    width: 25%;
  }

  .gset {
    top: 0px;
    left: 92px !important;
    margin: 0px !important;
  }
  .lf22 {
    left: 22px !important;
  }
  .gset {
    width: 20px !important;
  }
  .p0 {
    padding: 0px !important;
  }
  .advance {
    padding-left: 9px;
    padding-right: 0px;
    margin-left: 0px;
    margin-right: 0px;
  }
  .b-flex {
    display: block !important;
  }
}

@media (min-width: 700px) and (max-width: 786px) {
  div >>> .multiselect__select {
    width: 25%;
  }
  .res-m-0 {
    margin-left: 0px !important;
  }
  .res-m-wm {
    margin-left: 0px !important;
    width: 100% !important;
  }
}

@media (min-width: 800px) and (max-width: 4000px) {
  .res-hide-mm {
    display: none !important;
  }
}
.display {
  display: flex !important;
}
.property-margin {
  margin-right: 0px !important;
  margin-left: 15px;
}
.remove-margin {
  margin-bottom: 0px !important;
}

.btn-yellow:hover {
  color: #272b32 !important;
}
.bsd:hover {
  border: 1px solid #548f4d !important;
}

div >>> .slider-process {
  max-width: 1014px;
}

div >>> .slider {
  max-width: 1010px;
}

div >>> .slider-dot .slider-always {
  transform: translateX(998px) !important;
}
.mar-settg {
  margin-right: -7px;
  margin-left: -7px;
}

.fieldBed {
  margin-right: 0px !important;
  margin-left: 15px !important;
  height: 48px !important;
  line-height: 47px !important;
  width: 100%;
  padding: 12px 56px 12px 20px !important;
  border: 1px solid #ebebeb !important;
  transition: all 0.4s ease !important;
  position: relative !important;
  border-radius: 8px !important;
}
.advanceSlow {
  padding: 12px 4px 12px 10px !important;
  margin: 0 15px 0 15px !important;
}
.w-196 {
  width: 196px !important;
}

.b-flex {
  display: flex;
}

div >>> .multiselect__placeholder {
  padding: 0px;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  color: black;
  font-size: inherit;
}

div >>> .multiselect__input {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  color: black;
  font-size: inherit;
}
div >>> .multiselect__tags {
  margin-left: 15px;
  height: 50px;
  max-width: 182px;
  width: 182px;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  color: black;
  font-size: inherit;
  padding-top: 13px;
  border-radius: 8px;
  border: 1px solid #ebebeb;
  box-shadow: 0 0 0 8px rgb(255 255 255 / 20%);
  padding-left: 28px;
}

div >>> .multiselect__select {
  left: 83%;
}

::placeholder {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  color: black;
  font-size: inherit;
}

div >>> .multiselect__placeholder {
  padding-left: 4px;
}

.bedroomAdvance {
  color: rgb(97, 140, 87);
  font-size: 25px;
  height: 22px;
  width: 22px;
  padding-right: 2px;
  margin-top: -7px;
}

.remove-margin {
  margin-bottom: 0px !important;
}
.wide {
  width: 100% !important;
}

.opacity-to-zero {
  display: none;
}

.opacity-to-one {
  display: inherit;
}
</style>